<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">
      <b-col
        cols="12"
        md="6"
        class="left-side px-3 d-flex justify-content-center align-item-center"
      >
        <div class="inner-wrapper" data-anima="bottom">
          <span class="logo mb-5">
            <img src="@/assets/logo.png" />
          </span>

          <div class="voltar-login">
            <router-link to="/"
              ><img src="@/assets/img/icons/arrow-page.svg" />
              Voltar</router-link
            >
          </div>

          <div class="info-recuperacao" v-if="!token">
            <h5 ref="titulo_recupera">Esqueceu sua senha?</h5>
            <p ref="texto_recupera">
              Preecha abaixo seu endereço de e-mail e enviaremos as instruções
              necessárias para redefinir sua senha na Voomp.
            </p>
          </div>

          <div class="info-recuperacao" v-if="token">
            <h5>Nova senha</h5>
            <p>
              Preencha os campos abaixo para definir uma nova senha da sua conta
              Voomp.
            </p>
          </div>

          <b-form novalidate v-if="!sent" @submit.prevent="onSubmit">
            <b-form-group v-if="!token" label="Seu e-mail" label-for="email">
              <b-form-input
                id="email"
                placeholder="exemplo@exemplo.com"
                v-model="email"
                type="email"
                name="email"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('email') : null"
              >
                Por favor, insira seu e-mail.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group v-if="token" label="Senha" label-for="password">
              <b-form-input
                id="password"
                placeholder="••••••••"
                v-model="password"
                type="password"
                name="password"
                v-validate="'required|min:8'"
                ref="password"
              ></b-form-input>
              <span
                class="mostrar-senha"
                ref="mostrar_senha"
                @click="mostrarSenha"
                >Mostrar</span
              >
              <b-form-invalid-feedback
                :state="submit ? !errors.has('password') : null"
              >
                Por favor, insira sua senha com no mínimo 8 caracteres.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="token"
              label="Confirmar senha"
              label-for="confirm_password"
            >
              <b-form-input
                id="confirm_password"
                placeholder="••••••••"
                v-model="confirm_password"
                type="password"
                name="confirm_password"
                v-validate="'required|min:8|confirmed:password'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('confirm_password') : null"
              >
                Por favor, a senha de confirmação tem que ser igual a anterior.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="dark"
                class="d-inline-block col-12"
              >
                <BaseButton
                  variant="secondary"
                  v-if="!token"
                  ref="button"
                  :disabled="loading"
                  type="submit"
                  class="btn-submit"
                  @click="onSubmit"
                >
                  Recuperar minha senha
                </BaseButton>

                <Captcha
                  ref="Captcha"
                  v-if="token"
                  @changeVerify="changeVerify"
                >
                  <BaseButton
                    variant="secondary_outline"
                    v-if="token"
                    ref="button"
                    :disabled="loading"
                    type="submit"
                    class="btn-submit"
                    @click="onSubmit"
                  >
                    Salvar nova senha
                  </BaseButton>
                </Captcha>
              </b-overlay>
            </b-row>
          </b-form>

          <BaseButton
            variant="secondary_outline"
            v-if="sent"
            class="btn-submit mt-5"
            @click="returnLogin"
          >
            Ir para o Login
          </BaseButton>
        </div>
      </b-col>

      <b-col cols="12" md="6" class="right-side"> </b-col>
    </b-row>
  </b-container>
</template>
<script>
import PasswordRecoveryService from "@/services/resources/PasswordRecoveryService";

const service = PasswordRecoveryService.build();

import Captcha from "@/components/security/Captcha.vue";

export default {
  name: "Login",
  components: {
    Captcha,
  },
  data() {
    return {
      submit: false,
      email: "",
      password: "",
      confirm_password: "",
      success: true,
      loading: false,
      token: null,
      sent: false,
      captcha: "",
    };
  },
  methods: {
    changeVerify(e) {
      this.captcha = e;
      this.onSubmit("get");
    },
    returnLogin() {
      this.$router.push("/");
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = "Mostrar";
      }
    },

    onSubmit(get) {
      if (get !== "get" && this.token) {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;

          if (this.token) {
            this.changePassword();
          } else {
            this.requestToken();
          }
        }
      });
    },
    requestToken() {
      let data = {
        email: this.email,
        type: "REQUEST_TOKEN",
      };

      service
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            "Um link de recuperação foi enviado para seu e-mail!",
            {
              title: "Recuperação de senha",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );

          this.sent = true;
          this.$refs.titulo_recupera.innerText = "✔️ Verifique seu email!";
          this.$refs.texto_recupera.classList.add("sent");
          this.$refs.texto_recupera.innerHTML = `As orientações foram enviadas a <b>${this.email}</b>`;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePassword() {
      let data = {
        password: this.password,
        token: this.token,
        captcha: this.captcha,
        type: "CHANGE_PASSWORD",
      };

      service
        .create(data)
        .then(() => {
          this.$bvToast.toast("Sua senha foi alterada com sucesso!", {
            title: "Recuperação de senha",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (typeof this.$route.params.token != "undefined") {
      this.token = this.$route.params.token;
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: rgba(0, 33, 99, 0.1);
}

.right-side {
  background-image: url("~@/assets/img/login-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  background-color: #e5fcf4;
}

.left-side {
  background: #fff;
  padding: 0 !important;
  height: 100%;

  .inner-wrapper {
    margin-top: 40px;
    // width: 60%;
    padding: 1% 18%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}

.voltar-login {
  // max-width: 80px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ededf0;
}
.voltar-login a {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 15px;
}
.voltar-login img {
  transform: rotate(180deg);
  margin-right: 20px;
  margin-top: -2px;
}
.info-recuperacao h5 {
  font-size: 16px;
  font-weight: 600;
}
.info-recuperacao p {
  color: #81858e;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px 0 30px 0;
}
.info-recuperacao p.sent {
  font-size: 15px;
}
.btn-submit {
  width: 50%;
}
.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
@media screen and (max-width: 768px) {
  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }
  .btn-submit {
    margin-bottom: 40px;
  }
}
</style>
